<template>
  <Header />
  <main class="bg-gray-200">
    <div class="section section-lg pt-5 pt-md-7">
      <div class="container">
        <div class="row pt-5 pt-md-0">
          <Menu />
          <MenuMini />
          <div class="col-12 col-lg-9">
            <div class="card p-0 p-md-4 mb-4">
              <div class="card-header bg-white border-0">
                <h3 class="h5 mb-2">Personal API Key</h3>
                <p>
                  Always keep your API key secret. It functions as a combined
                  user ID and password for API Authentication. A new API key
                  could take up to a minute to activate.
                </p>
              </div>
              <ul class="list-group list-group-flush">
                <li class="list-group-item border-0 py-3 mb-3">
                  <div class="row mx-n2">
                    <div class="col col-lg px-2">
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control"
                          :value="input.key.value || 'Generate a new key'"
                          @focus="$event.target.select()"
                          readonly
                        />
                      </div>
                    </div>
                    <div class="col-auto px-2">
                      <button
                        type="button"
                        class="btn btn-secondary px-3"
                        v-on:click="keygen"
                        :disabled="btn.keygen.disabled"
                      >
                        Generate New Key
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <Footer />
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import Menu from '@/components/Menu'
import MenuMini from '@/components/MenuMini'

export default {
  components: {
    Header,
    Menu,
    MenuMini,
    Footer,
  },
  methods: {
    keygen() {
      this.btnKeygenDisable()
      this.$recaptcha()
        .then((token) =>
          this.$http
            .post(
              '/account/api',
              {},
              {
                headers: {
                  'ReCaptcha-token-v3': token,
                },
              }
            )
            .then((res) => this.setInputKey(res.data.apiKey))
        )
        .catch((error) => this.$error(error))
        .finally(() => this.btnKeygenEnable())
    },
    btnKeygenEnable() {
      this.btn.keygen.disabled = false
    },
    btnKeygenDisable() {
      this.btn.keygen.disabled = true
    },
    setInputKey(key) {
      if (key) {
        this.input.key.value = key
      } else {
        this.input.key.value = ''
      }
    },
  },
  mounted() {
    Promise.all([
      this.$recaptchaLoaded(),
      this.$http({
        url: '/account/api',
        method: 'GET',
      }).then((res) => {
        this.setInputKey(res.data.apiKey)
      }),
    ])
      .then(() => {
        this.ready = true
      })
      .catch((error) => this.$error(error))
      .finally(() => this.btnKeygenEnable())
  },
  data() {
    return {
      ready: false,
      input: {
        key: {
          value: 'Loading...',
        },
      },
      btn: {
        keygen: {
          disabled: true,
        },
      },
    }
  },
}
</script>
