<template>
  <div class="col-12 col-lg-3 mb-3 mb-lg-0">
    <div class="card border-gray-300 p-2">
      <div
        class="card-header bg-white border-0 text-center d-flex flex-row flex-lg-column align-items-center justify-content-center px-1 px-lg-4"
      >
        <div class="profile-thumbnail dashboard-avatar mx-lg-auto me-3">
          <img
            v-bind:src="user?.photoURL || '/assets/img/profile-empty.png'"
            class="card-img-top rounded-circle border-white"
            alt="Bonnie Green Portrait"
          />
        </div>
        <span class="my-0 my-lg-3 me-3 me-lg-0">{{ user?.email }}</span>
        <a class="btn btn-gray-300 btn-xs" v-on:click="logout"
          ><span class="me-2"><span class="fas fa-sign-out-alt"></span></span
          >Logout</a
        >
      </div>
      <div class="card-body p-2 d-none d-lg-block">
        <div class="list-group dashboard-menu list-group-sm">
          <router-link
            :to="{ name: 'subscription' }"
            class="d-flex list-group-item border-0 list-group-item-action"
            :class="subscription"
            >Subscription<span class="icon icon-xs ms-auto"
              ><span class="fas fa-chevron-right"></span></span
          ></router-link>
          <router-link
            :to="{ name: 'notification' }"
            class="d-flex list-group-item border-0 list-group-item-action"
            :class="notification"
            >Notification<span class="icon icon-xs ms-auto"
              ><span class="fas fa-chevron-right"></span></span
          ></router-link>
          <router-link
            :to="{ name: 'api' }"
            class="d-flex list-group-item border-0 list-group-item-action"
            :class="api"
            >API Key<span class="icon icon-xs ms-auto"
              ><span class="fas fa-chevron-right"></span></span
          ></router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
import { useRoute } from 'vue-router'
import { mapGetters } from 'vuex'
import { auth } from '@/firebase'

export default {
  computed: {
    subscription() {
      return useRoute().name === 'subscription' ? 'active' : ''
    },
    notification() {
      return useRoute().name === 'notification' ? 'active' : ''
    },
    api() {
      return useRoute().name === 'api' ? 'active' : ''
    },
    ...mapGetters(['user']),
  },
  methods: {
    async logout() {
      await auth.signOut()
      this.setUser(null)
      this.$router.push({ name: 'home' })
    },
    ...mapMutations(['setUser']),
  },
}
</script>
