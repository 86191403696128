<template>
  <header class="header-global">
    <nav
      id="navbar-main"
      aria-label="Primary navigation"
      class="navbar navbar-main navbar-expand-lg navbar-theme-primary headroom navbar-light navbar-transparent navbar-theme-secondary"
    >
      <div class="container position-relative">
        <a class="navbar-brand" href="#"
          ><img src="/assets/img/logo.png" alt="Logo"
        /></a>
        <div class="navbar-collapse collapse me-auto" id="navbar_global">
          <div class="navbar-collapse-header">
            <div class="row">
              <div class="col-6 collapse-brand">
                <a href="#"><img src="/assets/img/logo.png" alt="Logo" /></a>
              </div>
              <div class="col-6 collapse-close">
                <a
                  href="#navbar_global"
                  class="fas fa-times"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbar_global"
                  aria-controls="navbar_global"
                  aria-expanded="false"
                  title="close"
                  aria-label="Toggle navigation"
                ></a>
              </div>
            </div>
          </div>
          <ul class="navbar-nav navbar-nav-hover align-items-lg-center">
            <li class="nav-item">
              <router-link
                :to="{ name: 'home' }"
                class="nav-link"
                target="_navlink"
                >HOME</router-link
              >
            </li>
            <li class="nav-item">
              <a
                href="https://doc.chart-img.com"
                class="nav-link"
                target="_navlink"
                >API DOCUMENTATION</a
              >
            </li>
            <li class="nav-item">
              <a
                href="https://twitter.com/chart_img"
                class="nav-link"
                target="_navlink"
                >TWITTER</a
              >
            </li>
          </ul>
        </div>
        <div class="d-flex align-items-center">
          <button
            class="navbar-toggler ms-2"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbar_global"
            aria-controls="navbar_global"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
        </div>
      </div>
    </nav>
  </header>
</template>
