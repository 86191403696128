<template>
  <div class="col-12 d-lg-none">
    <div class="card bg-white border-gray-300 mb-4 mb-lg-5">
      <div class="card-body">
        <div class="row align-items-center">
          <div class="col-10 d-flex">
            <router-link
              :to="{ name: 'subscription' }"
              class="list-group-item list-group-item-action border-0 text-center me-2"
              :class="subscription"
              >Subscription</router-link
            >
            <router-link
              :to="{ name: 'notification' }"
              class="list-group-item list-group-item-action border-0 text-center me-2"
              :class="notification"
              >Notification</router-link
            >
            <router-link
              :to="{ name: 'api' }"
              class="list-group-item list-group-item-action border-0 text-center me-2 d-none d-sm-block border-0"
              :class="api"
              >API</router-link
            >
          </div>
          <div class="col-2 d-flex justify-content-center">
            <div class="btn-group dropleft">
              <button
                class="btn btn-link dropdown-toggle dropdown-toggle-split me-2 m-0 p-0"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span class="icon icon-sm"
                  ><span
                    class="fas fa-ellipsis-h icon-secondary fa-lg"
                  ></span> </span
                ><span class="sr-only">Toggle Dropdown</span>
              </button>
              <div class="dropdown-menu">
                <router-link
                  :to="{ name: 'api' }"
                  class="list-group-item list-group-item-action d-sm-none border-0"
                  :class="api"
                  >API</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useRoute } from 'vue-router'

export default {
  computed: {
    profile() {
      return useRoute().name === 'profile' ? 'active' : ''
    },
    subscription() {
      return useRoute().name === 'subscription' ? 'active' : ''
    },
    security() {
      return useRoute().name === 'security' ? 'active' : ''
    },
    notification() {
      return useRoute().name === 'notification' ? 'active' : ''
    },
    api() {
      return useRoute().name === 'api' ? 'active' : ''
    },
  },
}
</script>
